<template>
  <div>
    <vs-popup
      class="holamundo" 
      title="History" 
      :active.sync="popupActive"
      fullscreen
    >
      <custom-table
        class="w-full"
        ref="tableChild"
        :data="childData"
        :max-items="20"
        fullscreen
      >
        <template slot="thead">
          <vs-th width="10%" sort-key="project_category_name">ประเภทบ้าน</vs-th>
          <vs-th sort-key="project_house_sell_number">หมายเลขแปลง</vs-th>
          <vs-th sort-key="house_model_title">ชื่อแบบบ้าน</vs-th>
          <vs-th sort-key="house_model_code">รหัสแบบบ้าน</vs-th>
          <!-- <vs-th sort-key="is_sold_out">Sold Out (ระบุ)</vs-th> -->
          <vs-th sort-key="is_sold_out">สถานะการขาย</vs-th>
          <vs-th sort-key="project_house_sell_start_date">วันที่สร้างข้อมูล</vs-th>
          <vs-th sort-key="project_house_sell_end_date">วันที่สิ้นสุดการแก้ไข</vs-th>
          <vs-th sort-key="updated_at">แก้ไขล่าสุดโดย</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <!-- <vs-th>Action</vs-th> -->
        </template>
        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(child_tr) in data" 
              :data="child_tr" 
              :key="`item-row-${child_tr.uuid}`"
            >
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ child_tr.project_category_name }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''" >{{ child_tr.project_house_sell_number }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ child_tr.house_model_title }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ child_tr.house_model_code }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">
                <vs-chip color="danger" v-if="!child_tr.is_sold_out && !isExpired(child_tr)">
                  เปิดขาย
                </vs-chip>
                <vs-chip color="gray" v-if="!child_tr.is_sold_out && isExpired(child_tr)">
                  หมดอายุ
                </vs-chip>
                <vs-chip color="success" v-if="child_tr.is_sold_out">
                  Sold out
                </vs-chip>
              </td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ datepickerFn.updatedAtDatetimeFormat(child_tr.project_house_sell_start_date) }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ getEndDate(child_tr) }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ child_tr.updated_by }}</td>
              <td @click="editData(child_tr)" :class="isExpired(child_tr) ? `disabled-column` : ''">{{ datepickerFn.updatedAtDatetimeFormat(child_tr.updated_at) }}</td>
              <!-- <td class="whitespace-no-wrap">
                <div class="flex space-x-2">
                  <feather-icon
                    v-if="index === 0 && !hasDisbleItem(child_tr.project_house_sell_number)"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(child_tr.uuid)"
                  />
                </div>
              </td> -->
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </vs-popup>
    <vs-card>
      <div class="flex justify-between items-center">
        <h4 class="flex space-x-3 items-center">
          <span>{{ title }}</span>
          <img
            :src="require(`@/assets/images/flags/${$store.state.locale.currentLang}.png`)"
            :alt="$store.state.locale.currentLang"
            class="h-4 w-5"
          >
        </h4>
        <vs-button 
          :to="{ name: 'property-asset-project-home-sell-create', params: {
            project_id: this.$route.params.project_id
        } }">
          สร้างแบบบ้านพร้อมขาย
        </vs-button>
      </div>
      <vs-divider />
      <vs-tabs>
        <vs-tab
          label="บ้านพร้อมขาย"
          @click="currentTab = 'house_sell'"
        >
          <ProjectHomeSellTable
            :tableName="currentTab"
            :items="items"
            :childItems="childItems"
            :openHistory="openHistory"
            :isExpired="isExpired"
          />
        </vs-tab>
        <vs-tab
          label="บ้านตกแต่งพร้อมขาย"
          @click="currentTab = 'furnished_home'"
        >
          <ProjectHomeSellTable
            :tableName="currentTab"
            :items="items"
            :childItems="childItems"
            :openHistory="openHistory"
            :isExpired="isExpired"
          />
        </vs-tab>
      </vs-tabs>
      <div class="ml-6 flex flex-col space-y-1 md:flex-row md:space-x-4 button-tip lg:w-7/12">
        <div>
          <span class="text-warning">* คำอธิบายการใช้งาน: </span>
        </div>
        <div class="flex space-x-1 items-center">
          <feather-icon
            icon="FileTextIcon"
            svg-classes="w-4 h-4 stroke-current text-warning"
          />
          <span class="text-warning">รายการย้อนหลัง</span>
        </div>
        <div class="flex space-x-1 items-center">
          <feather-icon
            icon="CopyIcon"
            svg-classes="w-4 h-4 stroke-current text-warning"
          />
          <span class="text-warning">คัดลอกข้อมูล</span>
        </div>
        <div class="flex space-x-1 items-center">
          <feather-icon
            icon="EditIcon"
            svg-classes="w-4 h-4 stroke-current text-warning"
          />
          <span class="text-warning">แก้ไขข้อมูล</span>
        </div>
        <div class="flex space-x-1 items-center" v-if="isEditor">
          <feather-icon
            icon="TrashIcon"
            svg-classes="w-4 h-4 stroke-current text-warning"
          />
          <span class="text-warning">ลบข้อมูล</span>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
  import { ref, computed, onMounted, onBeforeUnmount } from '@vue/composition-api'

  import get from 'lodash/get'
  import isEmpty from 'lodash/isEmpty'
  // import sortBy from 'lodash/sortBy'
  import orderBy from 'lodash/orderBy'
  import moment from 'moment'
  import useDatepicker from '@/use/useDatepicker'

  import ProjectHomeSellTable from './ProjectHomeSellTable.vue'
  import CustomTable from '@/components/CustomTable'

  export default {
    components: { ProjectHomeSellTable, CustomTable },
    name: 'ProjectHomeSellList',
    setup(props, ctx) {
      const popupActive = ref(false)
      const projectHouseSellSelectId = ref('')
      const currentTab = ref('house_sell')
      const datepickerFn = useDatepicker()

      const isExpired = (data) => {
        const today = moment().format()
        const startDate = data.project_house_sell_start_date
        const soldEndDate = data.project_house_sell_sold_out_end_date
        const endDate = data.project_house_sell_end_date

        if (data && data.show_list_page === 'true' && !data.is_sold_out) return false
        if (data.is_sold_out) return !moment(today).isBetween(startDate, soldEndDate, 'day', '[]')
        return !moment(today).isBetween(startDate, endDate, 'day', '[]')
      }

      const groupItems = computed(() => {
        const groupItems = {}
        const allItems = get(ctx.root.$store.state.projectHouseSell, 'items', [])

        allItems.forEach((item) => {
          if (!groupItems[item.project_house_sell_number]) groupItems[item.project_house_sell_number] = []
          groupItems[item.project_house_sell_number].push(item)
        })

        const numberExist = orderBy(Object.keys(groupItems))

        numberExist.forEach((number) => {
          if (!isEmpty(groupItems[number])) groupItems[number] = orderBy(groupItems[number], ['created_at'], ['desc'])
        })

        return groupItems
      })

      const title = computed(() => {
        const lang = ctx.root.$store.state.locale.currentLang
        return get(ctx.root.$store.state.project, `project.${lang}.title`, '-')
      })

      const items = computed(() => {
        const firstItems = []

        const numberExist = Object.keys(groupItems.value)
        numberExist.forEach((number) => {
          const item = groupItems.value[number][0]
          if (currentTab.value === 'house_sell' && item.show_list_page === 'false') {
            firstItems.push(item)
          } else if (currentTab.value === 'furnished_home' && item.show_list_page === 'true') {
            firstItems.push(item)
          }
        })

        return orderBy(firstItems, ['updated_at'], ['desc'])
      })

      const childItems = computed(() => {
        const child = {}
        const numberExist = Object.keys(groupItems.value)

        numberExist.forEach(
          (number) => {
            child[number] = groupItems.value[number].filter((item) => {
              return isExpired(item) && !items.value.find((parentItem) => parentItem.uuid === item.uuid)
            })
          })

        return child
      })

      const isEditor = computed(() => ctx.root.$acl.check('editor'))

      ctx.root.$store.dispatch('updateBreadcrumbData', [
        { title: 'Home', url: '/' },
        { title: 'Project', url: `/property-asset/project/edit/${ctx.root.$route.params.project_id}` },
        { title: 'Home Sell List', active: true },
      ])

      onBeforeUnmount(() => {
        ctx.root.$store.dispatch('updateBreadcrumbData', [])
      })
      
      onMounted(() => {
        ctx.root.$store.commit('houseModel/SET_ITEM', {})
      })


      const editData = (data) => {
        popupActive.value = false
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
        ctx.root.$router.push({
          name: 'property-asset-project-home-sell-edit',
          params: { uuid: data.uuid },
        })
      }

      // const isLasted = (houseSellNumber, uuid) => {
      //   const allItems = get(ctx.root.$store.state.projectHouseSell, 'items', [])
      //   const sameNumberList = allItems.filter((item) => item.project_house_sell_number === houseSellNumber && isExpired(item))

      //   const orderList = sortBy(sameNumberList, ['updated_at'])

      //   return orderList[orderList.length - 1].uuid === uuid
      // }

      const getEndDate = (data) => {
        const soldEndDate = data.project_house_sell_sold_out_end_date
        const endDate = data.project_house_sell_end_date

        if (data.show_list_page === 'true' && !data.is_sold_out) {
          return '-'
        } else if (data.is_sold_out) {
          return datepickerFn.updatedAtDatetimeFormat(soldEndDate)
        }
        return datepickerFn.updatedAtDatetimeFormat(endDate)
      }


      const childData = computed(() => {
        return get(childItems.value, projectHouseSellSelectId.value, [])
      })

      const openHistory = (id) => {
        projectHouseSellSelectId.value = id
        popupActive.value = !popupActive.value
      }

      return {
        // isLasted,
        isExpired,
        isEditor,
        popupActive,
        projectHouseSellSelectId,
        groupItems,
        items,
        childItems,
        childData,
        title,
        currentTab,
        openHistory,
        datepickerFn,
        getEndDate,
        editData
      }
    },
  }
</script>

<style scoped>
  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .disabled-row {
    background-color: #f7fafc !important;
  }
  .disabled-column {
    opacity: 0.4;
  }
  .button-tip {
    margin-top: -3rem;
    margin-bottom: 1rem;
  }
</style>
