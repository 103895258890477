<template>
  <div class="table__wrapper">
    <vs-popup :active.sync="openModalNoti">
      <div class="flex flex-col justify-center items-center space-y-4">
        <vs-icon icon="error_outline" size="75px" color="orange"></vs-icon>
        <p class="text-3xl">กรุณาเลือกบ้านที่ต้องการ Highlight</p>
      </div>
    </vs-popup>
    <custom-table
      :ref="`table-${tableName}`"
      :max-items="itemsPerPage"
      :data="filterItems"
      :searchKeys="searchKeys"
      pagination
      search
    >
      <div 
        slot="header" 
        class="flex items-center flex-grow justify-between">
        <!-- ITEMS PER PAGE -->
        <div class="flex w-auto items-center">
          <datepicker
            v-model="filterStartDate"
            placeholder="เลือกวันเริ่มต้น"
            clear-button
            :format="customFormatter"
            :disabled-dates="maxFilterStartDate"
          />
            <span class="text-4xl mx-2"> - </span>
          <datepicker
            v-model="filterEndDate"
            placeholder="เลือกวันสิ้นสุด"
            clear-button
            :format="customFormatter"
            :disabled-dates="minFilterEndDate"
          />
          <span class="ml-8 text-lg">
            จำนวนบ้านทั้งหมด {{filterItemByDate.length}} หลัง
          </span>
          <span class="ml-2 text-lg text-success">
            ขายไปแล้ว {{soldOutLength}} หลัง
          </span>
          <span class="ml-2 text-lg text-danger">
            เปิดขาย {{availableLength}} หลัง
          </span>
          <span class="ml-2 text-lg text-gray">
            หมดอายุ {{expiredLength}} หลัง
          </span>
        </div>
        <div class="mt-4">
          <LhDropdownPerPage 
            :customClass="'p-2 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium'"
            :items="filterItems" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
          <vs-dropdown 
            vs-trigger-click 
            class="cursor-pointer mr-4 items-per-page-handler">
            <div
              class="p-2 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2">
                {{ filterLabel }}
              </span>
              <feather-icon 
                icon="ChevronDownIcon" 
                svg-classes="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="filterBy = 'all'">
                <span>แสดงทั้งหมด</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="filterBy = 'active'">
                <span>เปิดขาย</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="filterBy = 'sold-out'">
                <span>Sold out</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="filterBy = 'expired'">
                <span>หมดอายุ</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="project_house_sell_number">หมายเลขแปลง</vs-th>
        <!-- <vs-th sort-key="is_sold_out">Sold Out (ระบุ)</vs-th> -->
        <vs-th sort-key="is_default">แสดงเป็นบ้านหลัก</vs-th>
        <vs-th sort-key="status">การแสดงผลบนเว็บ</vs-th>
        <vs-th sort-key="is_sold_out">สถานะการขาย</vs-th>
        <vs-th sort-key="project_house_sell_start_date">เริ่ม</vs-th>
        <vs-th sort-key="project_house_sell_end_date">สิ้นสุด</vs-th>
        <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            :class="isExpired(tr) ? `disabled-row` : ''"
            v-for="(tr, index) in data" 
            :data="tr" 
            :key="`item-row-${tr.uuid}-${index}`"
          > 
            <td @click.stop="editData(tr)" :class="isExpired(tr) ? `td vs-table--td disabled-column` : 'td vs-table--td'" >
              {{ tr.project_house_sell_number }}
            </td>
            <!-- <vs-td>
              <vs-checkbox
                :name="`project-house-sell-sold-out-${tr.uuid}`"
                :value="tr.is_sold_out"
                :disabled="isExpired(tr)"
                @change="(event) => {
                  updateSoldOut(tr.uuid, event)
                }"
              />
            </vs-td> -->
            <td class="td vs-table--td">
              <vs-checkbox
                :name="`project-house-sell-sold-out-${tr.uuid}`"
                :value="tr.is_default"
                :disabled="isExpired(tr) || tr.status !== 'published'"
                @change="(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  updateDefault(tr.uuid, event)
                }"
              />
            </td>
            <td @click.stop="editData(tr)" :class="isExpired(tr) ? `td vs-table--td disabled-column` : 'td vs-table--td'" >
              {{ tr.status === 'published' ? 'แสดง' : 'ไม่แสดง'}}
            </td>
            <td @click.stop="editData(tr)" :class="isExpired(tr) ? `td vs-table--td disabled-column` : 'td vs-table--td'" >
              <vs-chip color="danger" v-if="!tr.is_sold_out && !isExpired(tr)">
                เปิดขาย
              </vs-chip>
              <vs-chip color="gray" v-if="!tr.is_sold_out && isExpired(tr)">
                หมดอายุ
              </vs-chip>
              <vs-chip color="success" v-if="tr.is_sold_out">
                Sold out
              </vs-chip>
            </td>
            <td @click.stop="editData(tr)" :class="isExpired(tr) ? `td vs-table--td disabled-column` : 'td vs-table--td'" >{{ datepickerFn.formatDate(tr.project_house_sell_start_date) }}</td>
            <td @click.stop="editData(tr)" :class="isExpired(tr) ? `td vs-table--td disabled-column` : 'td vs-table--td'" >{{ getEndDate(tr) }}</td>
            <td @click.stop="editData(tr)" :class="isExpired(tr) ? `td vs-table--td disabled-column` : 'td vs-table--td'" >{{ datepickerFn.formatDate(tr.updated_at) }}</td>
            <vs-td class="whitespace-no-wrap cursor-default">
              <div class="flex space-x-2">
                <feather-icon
                  class="cursor-pointer"
                  v-if="childItems[tr.project_house_sell_number] && childItems[tr.project_house_sell_number].length > 0 && isEditor"
                  icon="FileTextIcon"
                  svg-classes="w-5 h-5 hover:text-warning stroke-current"
                  @click="() => openHistory(tr.project_house_sell_number)"
                />
                <feather-icon
                  v-if="isExpired(tr)"
                  icon="CopyIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="duplicateContent(tr.uuid)"
                />
                <router-link
                  v-if="!isExpired(tr)"
                  :to="{ name: 'property-asset-project-home-sell-edit', params: { uuid: tr.uuid }}"
                  class="pt-2 color-text-link"
                >
                  <feather-icon
                    v-if="!isExpired(tr)"
                    icon="EditIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>
                <feather-icon
                  class="cursor-pointer"
                  v-if="isEditor"
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  @click="deleteItem(tr.uuid)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>

<script>
  import { ref, computed, onMounted, watch } from '@vue/composition-api'

  import get from 'lodash/get'
  import isEmpty from 'lodash/isEmpty'
  // import sortBy from 'lodash/sortBy'

  import useProjectHomeSellUtil from '@/use/useProjectHomeSellUtil'
  import useDatepicker from '@/use/useDatepicker'
  import useNotify from '@/use/useNotify'
  import moment from 'moment'

  import Datepicker from 'vuejs-datepicker'
  import LhDropdownPerPage from '@/components/LhDropdownPerPage'
  import CustomTable from '@/components/CustomTable'
  
  import env from '@/env'

  export default {
    name: 'ProjectHomeSellTable',
    components: {
      Datepicker,
      LhDropdownPerPage,
      CustomTable
    },
    props: {
      tableName: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      childItems: {
        type: Object,
        required: true
      },
      openHistory: {
        type: Function,
        required: true
      },
      isExpired: {
        type: Function,
        required: true
      }
    },
    setup(props, ctx) {
      const filterBy = ref('all')
      const { formData, buildPayload } = useProjectHomeSellUtil(ctx)
      const datepickerFn = useDatepicker()
      const notifyFn = useNotify(ctx)
      const { refs } = ctx
      const openModalNoti = ref(false)

      const isMounted = ref(false)
      const itemsPerPage = ref(10)

      const filterStartDate = ref(null)
      const filterEndDate = ref(null)

      const lifeTimeDuration = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_DURATION
      const lifeTimeUnit = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_UNIT

      const customFormatter = (date) => {
        return moment(date).format('DD/MM/YYYY')
      }

      const hasDisbleItem = (number) => {
        return props.isExpired(props.groupItems[number][0])
      }

      const filterLabel = computed(() => {
        if (filterBy.value === 'active') {
          return 'เปิดขาย'
        }
        if (filterBy.value === 'sold-out') {
         return 'Sold out'
        }

        if (filterBy.value === 'expired') {
         return 'หมดอายุ'
        }

        return 'แสดงทั้งหมด'
      })

      const queryProjectHouseSell = async (project_uuid, filterBy) => {
        let filter = [true, false]
        if (filterBy === 'active') {
          filter = [false]
        }
        if (filterBy === 'sold-out') {
          filter = [true]
        }

        if (!(project_uuid ? project_uuid : get(ctx.root.$store.state.project, 'project.uuid'))) {
          return
        }

        await ctx.root.$vs.loading()
        await ctx.root.$store.dispatch('projectHouseSell/fetch', {
          project_uuid: project_uuid ? project_uuid : get(ctx.root.$store.state.project, 'project.uuid'),
          filter,
        }).then(() => {
          return ctx.root.$vs.loading.close()
        })
        .catch(() => {
          return ctx.root.$vs.loading.close()
        })
      }

      const isEditor = computed(() => ctx.root.$acl.check('editor'))

      const updateSoldOut = (uuid, event) => {
        ctx.root.$store.dispatch('projectHouseSell/updateItemByUUID', {
          uuid,
          is_sold_out: event.target.checked
        }).then(() => {
          queryProjectHouseSell('', filterBy.value)
        })
      }

      const checkHighlight = () => {
        const allItems = get(ctx.root.$store.state.projectHouseSell, 'items', [])
          let hasHighlight = false
          allItems.forEach((item) => {
            if (!props.isExpired(item) && item.is_default && !hasHighlight) hasHighlight = true 
          })

          if (!hasHighlight && !isEmpty(allItems)) openModalNoti.value = true
      }

      const updateDefault = (uuid, event) => {
        ctx.root.$vs.loading()
        get(ctx.root.$store.state.projectHouseSell, 'items', [])
          .forEach((item) => {
            if (uuid === get(item, 'uuid') || !get(item, 'is_default')) {
              return
            }

            ctx.root.$store.dispatch('projectHouseSell/updateItemByUUID', {
              uuid: get(item, 'uuid'),
              is_default: false
            })
          })

        setTimeout(() => {
          ctx.root.$store.dispatch('projectHouseSell/updateItemByUUID', {
            uuid,
            is_default: event.target.checked
          }).then(() => {
            return queryProjectHouseSell('', filterBy.value)
          }).then(() => {
            checkHighlight()
            return ctx.root.$vs.loading.close()
          })
        }, 100)
      }

      const deleteItem = (uuid) => {
        const callback = async () => {
            await ctx.root.$store.dispatch('projectHouseSell/delete', uuid) 
            await queryProjectHouseSell('', filterBy.value)

            notifyFn.success({
              text: 'ลบข้อมูลเรียบร้อย'
            })
        }

        notifyFn.confirm({ callback })
      }

      ctx.root.$vs.loading()
      ctx.root.$store.dispatch('project/fetchItem', ctx.root.$route.params.project_id)
        .then((response) => {
          return queryProjectHouseSell(get(response, 'uuid'), filterBy.value)
        })
        .then(() => checkHighlight())
      .catch(() => {
        ctx.root.$vs.loading.close()
      })

      ctx.root.$store.dispatch('updateBreadcrumbData', [
        { title: 'Home', url: '/' },
        { title: 'Project', url: `/property-asset/project/edit/${ctx.root.$route.params.project_id}` },
        { title: 'Home Sell List', active: true },
      ])
      
      const duplicateContent = async (uuid) => {
        const callback = async () => {
          await ctx.root.$vs.loading()
          try {
            const response = await ctx.root.$store.dispatch('projectHouseSell/fetchOne', uuid)
            const lang = ctx.root.$store.state.locale.currentLang
            Object.assign(formData, response[lang])

            formData.uuid = null
            formData.is_default = false
            formData.is_sold_out = false
            formData.house_model = {
              label: get(response, `house_model.${lang}.house_model_title`),
              value: get(response, 'house_model.uuid')
            }

            await ctx.root.$store
              .dispatch('houseModel/fetchOne', `${formData.house_model.value}`)

            formData.house_model_id = get(response, 'house_model.id')
            formData.project_house_sell_self_gallery_selected = get(response, 'project_house_sell_files', [])
              .filter(v => v.attribute_code === 'project_house_sell_self_image')
            formData.project_house_sell_gallery_selected = get(
              get(response, 'project_house_sell_files', [])
                .find(v => v.attribute_code === 'house_model_image'),
              'uuid'
            )
            const iconSelected = get(ctx.root.$store.state.houseIconActivity, 'items')
              .find(v => v.id === parseInt(formData.project_house_sell_icon_activity_id))
              if (!isEmpty(iconSelected)) {
                formData.project_house_sell_icon_activity = {
                  label: get(iconSelected, 'house_icon_activity_title', null),
                  value: get(iconSelected, 'id', null),
                  image: get(iconSelected, 'house_icon_activity_files.0.image.0'),
                }
              }
            formData.project_house_sell_features_selected = get(response, 'project_house_sell_features', [])
              .map(v => v.house_function_feature_id)

            formData.project_house_sell_model_gallery_selected = get(response, 'project_house_sell_files', [])
              .filter(v => v.attribute_code === 'project_house_sell_public_image')
              .map(v => v.uuid)
            formData.show_list_page = get(response, `${lang}.show_list_page`) === 'true'

            if (get(response, `${lang}.project_house_sell_gallery_id`)) {
              const projectHouseSellGallerSelected = get(ctx.root.$store.state.houseSellGallery, 'filter_items')
                .find(v => {
                  return v.id === parseInt(get(response, `${lang}.project_house_sell_gallery_id`))
                })


                if (!isEmpty(projectHouseSellGallerSelected)) {
                  formData.project_house_sell_gallery = {
                    label: projectHouseSellGallerSelected.project_title,
                    value: projectHouseSellGallerSelected.id,
                    house_sell_gallery_files: projectHouseSellGallerSelected.house_sell_gallery_files
                  }

                  if (projectHouseSellGallerSelected.house_sell_gallery_files.length > 0) {
                    formData.project_house_sell_gallery_by_project_selected = 
                      get(response, 'project_house_sell_files', [])
                        .filter(v => v.attribute_code === 'project_house_sell_gallery')
                        .map(v => get(v, 'image_uuid', null))
                  }
                } else {
                  formData.project_house_sell_gallery = {}
                }
            }

            formData.project_house_sell_start_date = moment().format()
            formData.project_house_sell_end_date = moment().add(lifeTimeDuration, lifeTimeUnit).format()
            delete formData.project_house_sell_sold_out_end_date

            const payload = buildPayload()
        
            const resultUuid = await ctx.root.$store
            .dispatch('projectHouseSell/addItem', payload)

            ctx.root.$router.push({ name: 'property-asset-project-home-sell-edit', params: { uuid: resultUuid } })
          } catch (e) {
            notifyFn.error({ text: e })
          } finally {
            ctx.root.$vs.loading.close()
          }
        }
          
        notifyFn.confirm({ callback })
      }

      const getEndDate = (data) => {
        const soldEndDate = data.project_house_sell_sold_out_end_date
        const endDate = data.project_house_sell_end_date

        if (data.show_list_page === 'true' && !data.is_sold_out) {
          return '-'
        } else if (data.is_sold_out) {
          return datepickerFn.formatDate(soldEndDate)
        }
        return datepickerFn.formatDate(endDate)
      }

      const filterItemByDate = computed(() => {
        if ((!filterStartDate.value && !filterEndDate.value) || isEmpty(props.items)) return props.items
        const formatStartDate = filterStartDate.value ? datepickerFn.formatMoment(filterStartDate.value) : null
        const formatEndDate = filterEndDate.value ? datepickerFn.formatMoment(filterEndDate.value) : null

        return props.items.filter((item) => {

          let endDate = datepickerFn.formatMoment(item.project_house_sell_end_date)
          if (item.is_sold_out) endDate = datepickerFn.formatMoment(item.project_house_sell_sold_out_end_date)
          
          return (
            (
              (filterStartDate.value && moment(datepickerFn.formatMoment(item.project_house_sell_start_date)).isSameOrAfter(formatStartDate, 'd'))
              || !filterStartDate.value
            ) &&
            (
              (filterEndDate.value && moment(endDate).isSameOrBefore(formatEndDate, 'd'))
              || !filterEndDate.value
            )
          )
        })
      })

      const filterItems = computed(() => {
        let filtered = filterItemByDate.value
        if (isEmpty(filtered)) return filtered

        if (filterBy.value === 'active') {
          filtered = filtered.filter((item) => !item.is_sold_out && !props.isExpired(item))
        } else if (filterBy.value === 'sold-out') {
          filtered = filtered.filter((item) => item.is_sold_out)
        } else if (filterBy.value === 'expired') {
          filtered = filtered.filter((item) => !item.is_sold_out && props.isExpired(item))
        }

        return filtered
      })

      const soldOutLength = computed(() => {
        return filterItemByDate.value.filter((item) => item.is_sold_out).length
      })

      const availableLength = computed(() => {
        return filterItemByDate.value.filter((item) => !item.is_sold_out && !props.isExpired(item)).length
      })

      const expiredLength = computed(() => {
        return filterItemByDate.value.filter((item) => !item.is_sold_out && props.isExpired(item)).length
      })
      
      const minFilterEndDate = computed(() => {
        // const min = filterStartDate.value ? moment(filterStartDate.value).format() : null
        
        return filterStartDate.value ? { to: new Date(filterStartDate.value)} : {}
      })

      const maxFilterStartDate = computed(() => {
        // const max = filterEndDate.value ? moment(filterEndDate.value).add(1, 'd').format() : null
        
        return filterEndDate.value  ? { from: new Date(filterEndDate.value)} : {}
      })

      onMounted(async () => {
        isMounted.value = true
      })

      watch(
        () => itemsPerPage.value,
        () => {
          if (refs[`table-${props.tableName}`]) {
            refs[`table-${props.tableName}`].currentx = 1
          }
        }
      )

      const currentPage = computed(() => {
        if (isMounted.value && refs[`table-${props.tableName}`]) {
          return refs[`table-${props.tableName}`].currentx
        }
        return 0
      })

      const queriedItems = computed(() => {
        return refs[`table-${props.tableName}`] ? refs[`table-${props.tableName}`].queriedResults.length : get(filterItems, 'value', []).length
      })

      const editData = (data) => {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
        ctx.root.$router.push({
          name: 'property-asset-project-home-sell-edit',
          params: { uuid: data.uuid },
        })
      }

      return {
        formData,
        itemsPerPage,
        datepickerFn,
        filterBy,
        filterLabel,
        updateSoldOut,
        updateDefault,
        deleteItem,
        duplicateContent,
        // isLasted,
        filterItems,
        isEditor,
        hasDisbleItem,
        getEndDate,
        filterStartDate,
        filterEndDate,
        customFormatter,
        minFilterEndDate,
        maxFilterStartDate,
        soldOutLength,
        availableLength,
        expiredLength,
        filterItemByDate,
        currentPage,
        queriedItems,
        editData,
        searchKeys: ['project_house_sell_number', 'status', 'project_house_sell_start_date', 'project_house_sell_end_date'],
        openModalNoti
      }
    },
  }
</script>

<style scoped>
  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .disabled-row {
    background-color: #f7fafc !important;
  }
  .disabled-column {
    opacity: 0.4;
  }
  .button-tip {
    margin-top: -3rem;
    margin-bottom: 1rem;
  }
  .table__wrapper {
    min-height: 400px;
  }
</style>
